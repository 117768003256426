import axios from 'axios';
import Cookies from 'js-cookie';

const url="https://dev.nexmil.app";

const api = axios.create({
  baseURL:url ,
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token =Cookies.get('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);


// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
  
      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
  
        try {
          const response = await axios.get(`${url}/refreshToken`,{withCredentials: true});

          const authorizationToken = response.headers.authorization;
          Cookies.set('token', authorizationToken, { expires:5}); 
  
          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${authorizationToken}`;
          return axios(originalRequest);
        } catch (error) {
          // Handle refresh token error or redirect to login
          window.location.replace(window.location.origin);
        }
      }
  
      return Promise.reject(error);
    }
  );
  

export default api
