import React from "react";
import { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect } from "react";
import "./dashboard.css";
import DashboardHeader from "./dashboardHeader";
import TopHeader from "./topHeader";
import api from './api';



function Dashboard()
{
  const [userCounts, setUserCounts] = useState({});
  const [chartData, setChartData] = useState([]);
  const [topItemProducts, setTopItemProducts] = useState([]);
  useEffect(() =>
  {
    fetchData();
    fetchDatas();
    fetchTopItemProducts();
  }, []);
  const fetchTopItemProducts = async () => {
    try {
      const response= await api.get(`/redeemedItems`);

      setTopItemProducts(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setTopItemProducts([]);
    }
  };
  const fetchData = async () =>
  {
    try
    {
      // Make a request to your backend API with the authentication token
      const response = await api.get(`/all-users`);
     
      // Assuming the response contains user count data
      const { userCountByMonth } = response.data;

      // Update the state with the received data
      setUserCounts(userCountByMonth);
    } catch (error)
    {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchDatas = async () =>
  {
    try
    {
      const response = await api.get(`/monthly-referrals`);
      
      setChartData(response.data.resultArray);
    } catch (error)
    {
      console.error('Error fetching data from API:', error);
    }
  };

  const categoriesArray = chartData?.map((item) => item.month);
  const dataArray = chartData?.map((item) => item.count);


  const columnChartOptions = {
    chart: {
      type: "column",
      height: "230",
      width: "380",
      backgroundColor: "",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: categoriesArray,

      lineWidth: 0,
    },
    yAxis: {
      stackLabels: {
        enabled: true,
      },
     
      title: {
        text: "",
      },
      visible: false,
    },
    legend: false,

    colors: ["#0000FF"],
    series: [
      {
        dataLabels: {
          enabled: true,
        },
        name: "",

        data: dataArray,
      },
    ],
  };

  return (
    <div className="container-fluid">
      <div className="vh-100">
        <div className="main_dashboard h-100">
          <div className="row">
            <div className="ui bottom attached segment pushable main_dashboardContent">
              {/* <div
                  className="ui visible inverted left vertical sidebar menu" style={{ width: "280px", }}
                >
                  <a className="logo_item">
                    <img src="../../dashboard_logo.png" alt="logo" />
                  </a>
                  <div className="dashboard_links">
                      <Link to="/dashboard" className="item">
                        Dashboard
                      </Link>
                      <Link to="/manageusers" className="item">
                        Manage Users
                      </Link>
                      <Link to="/referrals" className="item">
                        Referrals
                      </Link>
                      <Link to="/redeemrequest" className="item">
                        Redeem Request
                      </Link>
                  </div>
                </div> */}
              <DashboardHeader />

              <div className="pusher dashboard_rightSide">

                <TopHeader />
                <div className="page-content">
                  <div className="">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="card card-height-100 grid-margin bg-grey border0">
                          <div className="card_title_heading">
                            <h3>Monthly Referrals</h3>
                          </div>
                          <div className="card-body">
                            <div className="d-flex justify-content-center">
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={columnChartOptions}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="card card-height-100 grid-margin bg-grey border0">
                          <div className="card_title_heading">
                            <h3>Total Users</h3>
                          </div>
                          <div className="card-body">
                            <div className="total-users-data">
                              <ul className="cal_center">
                                {Object.entries(userCounts).map(
                                  ([monthYear, count]) => (
                                    <li key={monthYear} className="m-1">
                                      <span>{monthYear}</span>{" "}
                                      <span className="number" style={{ color: "blue" }}>
                                        {count}
                                      </span>
                                    </li>
                                  )
                                )}         </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="card card-height-100 grid-margin bg-grey border0 totalItemRedeem">
                          <div className="card_title_heading">
                            <h3>Total Item Redeemed</h3>
                          </div>
                          <div className="card-body">
                          <div className="total-users-data">
                                    {topItemProducts!==undefined && topItemProducts?.map((item) => {

                                      return(
                              <ul className="cal_center">
                                        <li>
                                          <span className="f16"><b>{item.item}</b></span>
                                        </li>
                                        
                                       {item?.product?.map((items)=>{
                                        return(
                                        <li>
                                          <span>{items.product}</span>
                                          <span className="number" style={{ color: "#0b44ff" }}>{items.count}</span>
                                        </li>
                                        )
                                        
                                       }) 
                                      }
                                      </ul>
                                      )
                                    }) }
                                   </div>
                            
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="card card-height-100 grid-margin bg-grey border0">
                          <div className="card_title_heading">
                            <h3>Total product Redeemed</h3>
                          </div>
                          <div className="card-body">
                            <div className="total-users-data">
                              <ul className="cal_center">
                                <li>
                                  <span>July 2023</span>
                                  <span className="number" style={{ color: "#0b44ff" }}>23</span>
                                </li>
                                <li>
                                  <span>Aug 2023</span>
                                  <span className="number" style={{ color: "#0b44ff" }}>24</span>
                                </li>
                                <li>
                                  <span>Sept 2023</span>
                                  <span className="number" style={{ color: "#0b44ff" }}>26</span>
                                </li>
                                <li>
                                  <span>Oct 2023</span>
                                  <span className="number" style={{ color: "#0b44ff" }}>28</span>
                                </li>
                                <li>
                                  <span>Nov 2023</span>
                                  <span className="number" style={{ color: "#0b44ff" }}>30</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
