import "semantic-ui-css/semantic.min.css";
import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Dropdown } from "semantic-ui-react";
import DashboardHeader from "./dashboardHeader";
import TopHeader from "./topHeader";
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import api from './api';


function ManageUsers()
{
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [houseHoldList,setHouseHoldList]=useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [householdUser,setHouseholdUser]=useState(null);
  const [selectedUserIndex, setSelectedUserIndex] = useState([]);
  const handleDeleteModalOpen = () => setDeleteModalOpen(true);
  const handleDeleteModalClose = () => setDeleteModalOpen(false);
  const [errors, setErrors] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [houseHoldModalOpen,setHouseHoldModalOpen]=useState(false);

  const handleOpen = () =>
  {
    setModalOpen(true);
    setEditingUser(null);
  };
  const handleCheckboxChange = (index) =>
  {
    //setSelectedUserIndex((prevIndex) => (prevIndex === index ? null : index));
    if(selectedUserIndex.includes(index))
    {
      setSelectedUserIndex(selectedUserIndex.filter(prevIndex=>prevIndex!==index));
    }else{
      setSelectedUserIndex([...selectedUserIndex,index]);
    }
  };

  const handleDelete = async () =>
  {
     try
     {
      if (selectedUserIndex.length>0)
      {
        setLoading(true);
       // const userIdToDelete = users[selectedUserIndex].Id;
       const userIdToDelete = users.filter((user,index)=> selectedUserIndex.includes(index)).map((user)=>user.Id);

       const config = {
        data: JSON.stringify({ "userIds": userIdToDelete }),
        headers: {
          'Content-Type': 'application/json',
          
        },
      };

        const response = await api.delete(`/deleteUser`,config);
      
        if (response.status === 200)
        {
          toastr.success(`${response.data.message}`, '', { timeOut: 1000 });
        }
        setDeleteModalOpen(false);
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user.Id !== userIdToDelete)
        );
        fetchData();
        setSelectedUserIndex([]);
        setLoading(false);
      }
     } catch (error)
     {
      console.error("Axios Error:", error);
      toastr.error(`${error.response.data}`, '', { timeOut: 1000 });
       fetchData();
       setLoading(false);
     }
  };
  const handleClose = () => setModalOpen(false);

  const fetchData = async () =>
  {
    try
    {
      setLoading(true);
      const response = await api.get(`/getUsers`);
      if (response?.status === 200)
      {
        setUsers(response?.data);
       
      }
      setLoading(false);
    } catch (error)
    {
      if (error?.response != null && (error?.response?.status === 401 || error?.response?.status === 403))
      {
        toastr.error(`${error?.response.data.message ?? error?.response?.data}`, '', { timeOut: 1000 });
      }
      setLoading(false);
      console.error("Axios Error:", error);
    }
  };

  const fetchHouseHoldData = async () =>
  {
    try
    {
      setLoading(true);
      const response = await api.get(`/getHouseHoldUsers`);
      if (response?.status === 200)
      {
        setHouseHoldList(response?.data?.users);
      }
      setLoading(false);
    } catch (error)
    {
      if (error?.response != null && (error?.response?.status === 401 || error?.response?.status === 403))
      {
        toastr.error(`${error?.response.data.message ?? error?.response?.data}`, '', { timeOut: 1000 });
      }
      setLoading(false);
      console.error("Axios Error:", error);
    }
  };

  useEffect(() =>
  {
    fetchData();
    fetchHouseHoldData();
  }, []);

  const accountTypes = [
    {
      key: "Super Administrator",
      text: " Super Administrator",
      value: "Super Administrator",
    },
    { key: "Admin", text: "Admin", value: "Admin" },
    { key: "User", text: "User", value: "User" },
  ];

  const handleEditUser = (userId, user) =>
  {
    setEditingUser(user);
    setModalOpen(true);
  };
  const handleHouseholdUser=(userId,user)=>{
    setHouseholdUser(user);
    setHouseHoldModalOpen(true);
  };
  const validateEditingUserData = () =>
  {
    let error = false;
    if (!editingUser || !editingUser.FirstName)
    {
      setErrors(true);
      error = true;
      toastr.error('First Name is required', '', { timeOut: 1000 });
    }

    if (!editingUser || !editingUser.LastName)
    {
      setErrors(true);
      error = true;
      toastr.error('Last Name is required', '', { timeOut: 1000 });
    }

    if (!editingUser || !editingUser.Email)
    {
      setErrors(true);
      error = true;
      toastr.error('Email is required', '', { timeOut: 1000 });
    }
    if (!editingUser || !editingUser.PhoneNumber)
    {
      setErrors(true);
      error = true;
      toastr.error('PhoneNumber is required', '', { timeOut: 1000 });
    }
    if (!editingUser || !editingUser.AccountType)
    {
      setErrors(true);
      error = true;
      toastr.error('Account Type is required', '', { timeOut: 1000 });
    }

    if (!editingUser || !editingUser.Password)
    {
      setErrors(true);
      error = true;
      toastr.error('Password is required', '', { timeOut: 1000 });
    }

    return error ?? false;
  };
  const handleSaveEdit = async () =>
  {
    try
    {
      const isError = validateEditingUserData();
      if (isError)
      {
        return;
      }

      setLoading(true);

      const data = {
        Email: editingUser.Email,
        Password: password ?? null,
        FirstName: editingUser.FirstName,
        LastName: editingUser.LastName,
        AccountType: editingUser.AccountType,
        PhoneNumber:editingUser.PhoneNumber,
        HouseholdName:editingUser.household,
        UserIds:editingUser.household_User
      };

      const url = editingUser.Id
        ? `/updateUser?Id=${editingUser.Id}`
        : `/register`;

      const response = editingUser.Id
        ?   await api.put(url,data)
        :  await api.post(url,data);

      if (response.status === 200)
      {
        setModalOpen(false);
        toastr.success(`${response?.data?.message}`, '', { timeOut: 1000 });
       
      }
      setPassword("");
      setEditingUser(null);
      setSelectedUserIndex([]);
      fetchData();
      setLoading(false);
    } catch (error)
    {
      console.error("Save error:", error);
      if (error?.response?.status !== 200)
      {
        setErrors(true);
        if (error?.response != null)
        {
          toastr.error(`${error?.response.data.message ?? error?.response?.data}`, '', { timeOut: 1000 });
        }
      }
      setLoading(false);
    }
  };

const searchOptions=(data)=>{
 
    const options = data?.map((item, index) => ({
      key: item.id,
      text: `${item.fullName}-${item.email}`,
      value: item.id,
    }));
    return options;
  
};
  


 const customStyle={
    backgroundColor:"#2185d0",
    color:'white',
    borderRadius:'12px'
  }

  return (
    <div className="container-fluid">
      <div className="vh-100">
        <div className="main_dashboard h-100">
          <div className="row">
            <div className="ui bottom attached segment pushable main_dashboardContent">
              <DashboardHeader />

              <div className="pusher dashboard_rightSide">
                <TopHeader />
                <div className="page-content">
                  <div className="row">
                    <div class="col-sm-12">
                      <div class="card_title_heading">
                        <h3>Manage Users</h3>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm-12">
                      <div class="text-right top_btn">
                        <div class="d-flex gap-x-2 justify-content-end">
                          <button
                            onClick={handleOpen}
                            class="rounded-pill btn btn-primary btn-xs m120"
                          >
                            Add New User
                          </button>
                          <button
                            onClick={handleDeleteModalOpen}
                            class="rounded-pill btn btn-danger btn-xs m120"
                            disabled={selectedUserIndex.length <= 0}
                          >
                            Delete
                          </button>

                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="table-container theme_table">
                    <table className="ui celled table table-borderless">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Account Type</th>
                          <th>Email</th>
                          <th>HouseHold</th>
                          <th>Actions </th>
                          <th className="action_content"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                style={{ width: "13px", height: "13px", marginRight: "4px" }}
                                checked={selectedUserIndex.includes(index)}
                                onChange={() => handleCheckboxChange(index)}
                              />
                              {user.FirstName}
                            </td>
                            <td>{user.LastName}</td>
                            <td>{user.AccountType}</td>
                            <td>{user.Email}</td>
                            {/* <td onClick={handleOpen} style={{textDecoration:'underline',textDecorationColor:'blue',color:'blue'}}>{user.LastName}</td> */}
                            <td> <a onClick={()=> handleHouseholdUser(user.Id, user)} href="#" style={{textDecoration:'underline',textDecorationColor:'blue',color:'blue'}}>{user?.household?user.household:"N/A"}</a></td>
                            
                            <td>
                              <button
                                onClick={() => handleEditUser(user.Id, user)}
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                }}
                                disabled={(selectedUserIndex.length>1 || selectedUserIndex.length===0)?"true":selectedUserIndex[0] !== index}
                              >
                                <i className="fas fa-edit"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loader_content">
          <img src="Spinner-1s-200px.svg" alt="Loading" />
        </div>
      )}
      <Modal
        open={modalOpen}
        onClose={handleDeleteModalClose}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
        size="mini"
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "400px",
          margin: "auto",
          maxHeight: "90%",
          height: "auto",
        }}
      >
        <Modal.Header>
          <div className="modal-header">
            <span>{editingUser?.Id == null ? "Add New User " : "Edit User"}</span>
            <button className="close_btn" onClick={handleClose} style={{ float: 'right' }}>
              &times;
            </button>
          </div>

        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>First Name</label>
              <input
                placeholder="First Name"
                value={editingUser ? editingUser.FirstName : ""}
                onChange={(e) =>
                  setEditingUser({ ...editingUser, FirstName: e.target.value })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Last Name</label>
              <input
                placeholder="Last Name"
                value={editingUser ? editingUser.LastName : ""}
                onChange={(e) =>
                  setEditingUser({ ...editingUser, LastName: e.target.value })
                }
              />

            </Form.Field>
            <Form.Field>
              <label>Email</label>
              <input
                placeholder="Email"
                value={editingUser ? editingUser.Email : ""}
                onChange={(e) =>
                  setEditingUser({ ...editingUser, Email: e.target.value })
                }
              />

            </Form.Field>
            <Form.Field>
              <label>Phone #</label>
              <input placeholder="Phone #"
                maxLength={10}
                value={editingUser ? editingUser.PhoneNumber : ""}
                // Update the value of the input with the corresponding user data
                onChange={(e) =>
                  setEditingUser({ ...editingUser, PhoneNumber: (/^[0-9]*$/.test(e.target.value)) ? e.target.value : '' })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Household Name</label>
              <input placeholder="Household Name"
                value={editingUser ? editingUser.household : ""}
                onChange={(e) =>
                setEditingUser({ ...editingUser, household: e.target.value })
              }
              />
            </Form.Field>
            <Form.Field>
              <label>Household</label>
                <Dropdown
                
    placeholder='Household'
    fluid
    multiple
    search
    selection
    clearable
    options={searchOptions(houseHoldList)}
  onChange={(e, { value }) =>
                  setEditingUser({ ...editingUser, household_User: value })
                }
    value={editingUser ? editingUser?.household_User:""}
   renderLabel={(item,index)=>({
    content:`${item.text}`,
    style:editingUser?.household_User?.includes(item.value)?customStyle:{}
   })}
  />

            </Form.Field>
            <Form.Field>
              <label>Account Type</label>
              <Dropdown
                placeholder="Select Account Type"
                fluid
                selection
                options={accountTypes}
                value={editingUser ? editingUser.AccountType : ""}
                onChange={(e, { value }) =>
                  setEditingUser({ ...editingUser, AccountType: value })
                }
              />


            </Form.Field>
            <Form.Field>
              <label>Password</label>
              <input
                placeholder="Password"
                value={editingUser?.Id ? password : editingUser?.Password}
                onChange={(e) =>
                {
                  setEditingUser({ ...editingUser, Password: e.target.value })
                  setPassword(e.target.value)
                }
                }
              />

            </Form.Field>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <div className="text-right">
          <Button color="grey" onClick={handleClose}>
              Close
            </Button>
            <Button color="blue" onClick={handleSaveEdit} >
              {editingUser?.Id == null ? "Save " : "Update"}
            </Button>
          </div>
        </Modal.Actions>
      </Modal>

      {/* Delete Modal */}

      <Modal
        open={deleteModalOpen}
        onClose={handleDeleteModalClose}
        size="mini"
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "450px",
          margin: "auto",
          maxHeight: "70%",
          height: "auto",
        }}
      >
        <Modal.Header>Delete User</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete the selected user?</p>
        </Modal.Content>
        <Modal.Actions>
          <div className="text-right">
            <Button color="grey" onClick={handleDeleteModalClose}>
              Cancel
            </Button>
            <Button color="red" onClick={handleDelete}>
              Delete
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
 {/* HouseHold Modal */}
      <Modal
        open={houseHoldModalOpen}
        onClose={()=>setHouseHoldModalOpen(false)}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
        size="mini"
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "400px",
          margin: "auto",
          maxHeight: "90%",
          height: "auto",
        }}
      >
        <Modal.Header>
          <div className="modal-header">
            <span>{ "Household Details"}</span>
            <button className="close_btn" onClick={()=>setHouseHoldModalOpen(false)} style={{ float: 'right' }}>
              &times;
            </button>
          </div>

        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>First Name</label>
              <input 
                placeholder="First Name"
                value={householdUser ? householdUser.FirstName : ""}
              />
            </Form.Field>
            <Form.Field>
              <label>Last Name</label>
              <input
                placeholder="Last Name"
                value={householdUser ? householdUser.LastName : ""}
              />

            </Form.Field>
            <Form.Field>
              <label>Email</label>
              <input
                placeholder="Email"
                value={householdUser ? householdUser.Email : ""}
              />

            </Form.Field>
            <Form.Field>
              <label>Phone #</label>
              <input placeholder="Phone #"
                maxLength={10}
                value={householdUser ? householdUser.PhoneNumber : ""}
              />
            </Form.Field>
            <Form.Field>
              <label>Household Name</label>
              <input placeholder="Household Name"
                value={householdUser?.household ? householdUser.household: ""}
              />
            </Form.Field>
            <Form.Field>
              <label>Household</label>
              <Dropdown
               disabled="true"
    placeholder='Household'
    fluid
    multiple
    search
    selection
    clearable
    options={searchOptions(houseHoldList)}
   value={householdUser?.household_User ? householdUser.household_User : ""}
   renderLabel={(item,index)=>({
    content:`${item.text}`,
    style: householdUser?.household_User?.includes(item.value)?customStyle:{}
   })}
  />
            </Form.Field>
            <Form.Field>
              <label>Account Type</label>
              <Dropdown
               disabled="true"
                placeholder="Select Account Type"
                fluid
                selection
                options={accountTypes}
                value={householdUser ? householdUser.AccountType : ""}
              />


            </Form.Field>
            <Form.Field>
              <label>Password</label>
              <input
                placeholder="Password"
                value={householdUser?.Id ? password : householdUser?.Password}
              />

            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
    </div>

  );
}

export default ManageUsers;
