import React from "react";
import { useState } from "react";
import { Form,Input} from "semantic-ui-react";
import { useEffect } from "react";
import "./dashboard.css";
import api from './api';
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import { useLocation} from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";




function ForgotPassword()
{
   // const navigate = useNavigate();

    const location = useLocation();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [tokenExpired,setTokenExpired]=useState(false);
    const [passwordUpdated,setPasswordUpdated]=useState(false);
    const [loading, setLoading] = useState(false);


    const getTokenFromQueryString = () => {
       const searchParams = new URLSearchParams(location.search);
        return searchParams.get('token');

      };

    useEffect(() => {
    validateToken();
      }, [location]);

   const validateToken=()=>{
      const token=  getTokenFromQueryString();
     const { exp } = jwtDecode(token);
     console.log(jwtDecode(token));
      // Get the current time (in seconds since Unix epoch)
      const currentTime = Math.floor(Date.now() / 1000);

      // Check if the token has expired
      if (exp < currentTime) {
          setTokenExpired(true);
      } else {
          setTokenExpired(false);
      }
   };

    const validateEditingUserData = () =>
    {
      let error = false;
      if (!newPassword)
      {
        error = true;
        toastr.error('Password is required', '', { timeOut: 1000 });
      }
      if (newPassword && !confirmPassword)
      {
        error = true;
        toastr.error('Confirm Password is required', '', { timeOut: 1000 });
      }

      if (newPassword && confirmPassword && newPassword !== confirmPassword)
      {
        error = true;
        toastr.error('Confirm Password not matched', '', { timeOut: 1000 });
      }
      return error ?? false;
    };

    const handleSave = async () =>
    {
        validateToken();
      try
      {
        const isError = validateEditingUserData();
        if (isError)
        {
          return;
        }

        setLoading(true);
          const token=  getTokenFromQueryString();
     const { email } = jwtDecode(token);
        const data = {
            email: email,
            newPassword: newPassword,
            confirm_newPassword: confirmPassword,
          };
        const url ="/changePassword";
        const response= await api.put(url,data);
        
        if (response.status === 200)
      {
        toastr.success(`${response?.data?.message}`, '', { timeOut: 1000 });
        setNewPassword('');
        setConfirmPassword('');
        setLoading(false);
       // navigate('/login');
       setPasswordUpdated(true);
      }
      } catch (error)
      {
        setLoading(false);
        toastr.error(`${error?.response.data.message ?? error?.response?.data}`, '', { timeOut: 1000 });
       
      }
    
    };


  return (
     <div className="container-fluid">
     <section className="vh-100">
       <div className="container h-100">
         <div className="row align-items-center mvh100">
           <div className="col col-xl-12">
             <div className="login_card">
               <div className="login_cardBody">
                 <Form >
                   <div className="form-group text-center">
                     <div className="d-flex justify-content-center align-items-center">
                       <img src="../../Login_logo.png" alt="Logo" />
                     </div>
                   </div>
                   {loading && (
        <div className="loader_content">
          <img src="Spinner-1s-200px.svg" alt="Loading" />
        </div>
      )}
                   {tokenExpired && <div className="form-group text-center" >
                     <div className="d-flex justify-content-center align-items-center">
                       <label className="form-label bold" style={{color:'red',fontSize:'16px'}}>Session has expired !!!</label>
                     </div>
                   </div>}
                   {passwordUpdated && <div className="form-group text-center" >
                     <div className="d-flex justify-content-center align-items-center">
                       <label className="form-label bold" style={{color:'green',fontSize:'16px'}}>Please open up your app to login.</label>
                     </div>
                   </div>}


                   {!tokenExpired && !passwordUpdated && <div className="form-group" >
                     <div className="d-flex justify-content-between align-items-center">
                       <label className="form-label bold">New Password</label>
                       <div
                         className="icon_password d-flex gap-2 align-items-center"
                         onClick={() => setShowPassword(prev => !prev)}
                         style={{ cursor: "pointer" }}
                       >
                         <i
                           className={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"
                             }`}
                         ></i>
                         {showPassword ? "Hide" : "Show"}
                       </div>
                     </div>
                     <Input 
                       type={showPassword ? "text" : "password"}
                       className="form-control"
                       style={{padding:'0px '}}
                       name="password"
                       placeholder="Enter New Password"
                       onChange={(e) => setNewPassword(e.target.value)}
                      // onSubmit={handleSave}
                      // required
                     />
                    
                   </div>}
                   {!tokenExpired && !passwordUpdated && <div className="form-group">
                     <div className="d-flex justify-content-between align-items-center">
                       <label className="form-label bold">Confirm Password</label>
                       <div
                         className="icon_password d-flex gap-2 align-items-center"
                         onClick={() => setShowConfirmPassword(prev => !prev)}
                         style={{ cursor: "pointer" }}
                       >
                         <i
                           className={`fas ${showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                             }`}
                         ></i>
                         {showConfirmPassword ? "Hide" : "Show"}
                       </div>
                     </div>
                     <Input 
                       type={showConfirmPassword ? "text" : "password"}
                       className="form-control"
                       style={{padding:'0px '}}
                       name="confirmPassword"
                       placeholder="Enter Confirm Password"
                       onChange={(e) => setConfirmPassword(e.target.value)}
                      // onSubmit={handleSave}
                      // required
                     />
                     
                   </div>}
                  
                  {!tokenExpired && !passwordUpdated && <div className="mb-4 text-center">
                     <button
                       className="btn btn-success btn-block fw-bold"
                       onClick={handleSave}
                       type="submit"
                     >
                       Reset Password
                     </button>
                   </div>}
                 </Form>
               </div>
             </div>
           </div>
         </div>
       </div>
     </section>
   </div>
  );
}

export default ForgotPassword;
