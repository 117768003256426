// App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "./login.css";
import "./manageusers";
import "./dashboard.css";
import Login from "./login";
import ManageUsers from "./manageusers";
import Referrals from "./referrals";
import RedeemRequest from "./redeemrequest";
import Dashboard from "./dashboard";
import DashboardHeader from "./dashboardHeader";
import TopHeader from "./topHeader";
import ForgotPassword from "./forgotPassword";

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="manageusers" element={<ManageUsers />} />
          <Route path="referrals" element={<Referrals />} />
          <Route path="redeemrequest" element={<RedeemRequest />} />
          <Route path="dashboard" element={<Dashboard />} />
          {/* <Route path="dashboardheader" element={<DashboardHeader />} /> */}
          {/* <Route path="topheader" element={<TopHeader />} /> */}
          <Route path="resetpassword" element={<ForgotPassword />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
